

























import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

export default defineComponent({
  name: "ConcatArchiveModal",
  components: {
    SelectFilter,
  },
  data() {
    return {
      generalFund: "",
    };
  },
  computed: {
    ...mapGetters("fundCatalogView", ["data", "selectedIds"]),
    items(): { title: string; value: string }[] {
      const selectedObject = Object.entries(this.selectedIds).reduce((res, [key, value]) => {
        if (value) {
          res[key] = true;
        }
        return res;
      }, {} as { [key: string]: true });

      return this.data
        .filter((item: IArchiveElement) => {
          return selectedObject[item.id] && !item.isDeleted;
        })
        .map((item: IArchiveElement) => {
          return {
            title: item.name,
            value: item.id,
          };
        });
    },
  },
  methods: {
    ...mapMutations("fundCatalogView", ["setSelectedIds"]),
    ...mapActions("fundCatalogView", ["refreshScroll"]),
    ...mapActions("fundCatalogView", ["concatFunds"]),
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    async concatFund() {
      if (!this.generalFund) {
        showNotification("Выберите фонд, который останется после объединения (объединенный).");
        return;
      }
      const isSave = await this.concatFunds({
        masterFundId: this.generalFund,
        mergedFundId: this.items
          .filter((item) => {
            return item.value !== this.generalFund;
          })
          .map((item) => item.value),
      });

      if (isSave) {
        showNotification("Фонды успешно объеденены.", NOTIFICATION_STATUS.SUCCESS);
        this.refreshScroll();
        this.setSelectedIds({});
        this.closeModal();
      } else {
        showNotification("Ошибка объеденения фондов.");
      }
    },
  },
});
